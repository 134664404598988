import * as helper from "~/utils/helper_program";
import { createMemo, createSignal, For, Match, Show, Switch } from "solid-js";
import { hasNoEscape } from "~/utils/no_escape";
import imgProxy from "~/utils/imgproxy";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import {
  closingDelivery,
  closingDeliveryInThePast,
  deliveryTrimester,
  getBestReturnRate,
  getPinelAndPinelPlusZones,
  getPinelPlusZones,
  getPinelZones,
  getSmallestVatRate,
  hasBareOwnershipSlice,
  isPrixEncadresOverride,
} from "~/utils/helper_program";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import NouveauNeufLogos from "~/components/shared/NouveauNeufLogos";

import type { ProgramWrapper } from "~/utils/program_wrapper";
import type {
  FeatureReferenceField,
  ParagraphStrength,
  TermEntityReferenceField,
} from "~/types/drupal_jsonapi";

import IconTrendingUp from "~/img/icons/trending_up.svg";
import IconTimer from "~/img/icons/timer.svg";
import IconAccountBalanceWallet from "~/img/icons/account_balance_wallet.svg";
import IconEuro from "~/img/icons/euro.svg";
import IconPercent from "~/img/icons/percent.svg";
import IconSecurity from "~/img/icons/security.svg";
import IconSell from "~/img/icons/sell.svg";
import IconExpandMore from "~/img/icons/expand_more.svg";
import IconExpandLess from "~/img/icons/expand_less.svg";
{
  /* Commented for Monthly Payments
import IconClose from "~/img/icons/close.svg";
*/
}

import IconCampaign48pOutline from "~/img/icons/campaign_48dp_outline.svg";
import IconLocationOn48pOutline from "~/img/icons/location_on_48dp_outline.svg";
import IconPercent48pOutline from "~/img/icons/percent_48dp_outline.svg";

import "./Presentation.css";
import Tabs from "~/components/Program/Components/Tabs";

const [detailIsExpanded, setDetailIsExpanded] = createSignal(false);

type PresentationProps = {
  wrapper: ProgramWrapper;
};

export default function Presentation(props: PresentationProps) {
  const settings = useDrupalSettingsContext();

  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <section
        class="program-presentation"
        data-ga-zone="introduction"
        id="presentation"
      >
        <div class="flex-row" data-test="introduction">
          <div class="right-part">
            <Show
              when={
                settings.nouveau_neuf_enabled &&
                props.wrapper.program.field_segments_mkg &&
                props.wrapper.program.field_segments_mkg?.length > 0
              }
            >
              <NouveauNeufLogos
                zone="program"
                segmentsMkg={props.wrapper.program.field_segments_mkg!}
              />
            </Show>

            <Strengths wrapper={props.wrapper} />

            <ProgramInfos wrapper={props.wrapper} />

            {/*<MonthlyPayments wrapper={props.wrapper} />*/}
          </div>
          <div class="left-part">
            <Show
              when={!helper.isOutOfStock(props.wrapper)}
              fallback={<div class="tabs-spacer" />}
            >
              <Tabs wrapper={props.wrapper} />
            </Show>

            <Introduction wrapper={props.wrapper} />

            {/* details buttons */}
            <button
              type="button"
              class="btn"
              classList={{ disabled: detailIsExpanded() }}
              id="show-program-details"
              onClick={() => {
                sendClick("btn-details-open", "introduction");
                setDetailIsExpanded(true);
                window.location.href = "#details";
              }}
              data-test="btn-details-show"
            >
              Voir le programme en détails{" "}
              <i aria-hidden="true" class="cog-icon">
                <IconExpandMore />
              </i>
            </button>
            <button
              type="button"
              class="btn"
              classList={{ active: detailIsExpanded() }}
              id="hide-program-details"
              onClick={() => {
                sendClick("btn-details-close", "introduction");
                setDetailIsExpanded(false);
              }}
              data-test="btn-details-hide"
            >
              Replier{" "}
              <i aria-hidden="true" class="cog-icon">
                <IconExpandLess />
              </i>
            </button>
          </div>
        </div>

        <ProgramDetails wrapper={props.wrapper} />
      </section>
    </>
  );
}

function ProgramInfos(props: PresentationProps) {
  const settingsContext = useDrupalSettingsContext();
  const [, { setMediaOverlay }] = useMediaOverlayContext();
  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <div class="flex-items" data-test="infos">
        {/* FIRST COL */}
        <div class="item" data-test="delivery">
          <i aria-hidden="true" class="cog-icon">
            <IconCampaign48pOutline />
          </i>
          <div class="item-content">
            <strong>Livraison</strong>
            {props.wrapper.program.field_sales_state?.name.toUpperCase()}
            <Show when={helper.isPreview(props.wrapper)}>
              . <span>&Agrave; découvrir prochainement</span>
            </Show>
            <Show
              when={
                !helper.isPreview(props.wrapper) &&
                !props.wrapper.program.field_date_delivery_hide &&
                deliveryTrimester(props.wrapper)
              }
            >
              <Show
                fallback={
                  <>
                    .{" "}
                    <span>
                      Livraison à partir du{" "}
                      <span innerHTML={deliveryTrimester(props.wrapper)} />
                    </span>
                  </>
                }
                when={
                  props.wrapper.program.field_program_type
                    ?.drupal_internal__tid ===
                  settingsContext.program_types.terrains
                }
              >
                <>
                  .{" "}
                  <span>
                    Disponible à partir du{" "}
                    <span innerHTML={deliveryTrimester(props.wrapper)} />
                  </span>
                </>
              </Show>
            </Show>
            <Show
              when={
                props.wrapper.program.field_program_type
                  ?.drupal_internal__tid !==
                  settingsContext.program_types.terrains &&
                closingDelivery(props.wrapper)
              }
            >
              <Show
                when={
                  (props.wrapper.program.field_dwelling_type &&
                    props.wrapper.program.field_dwelling_type!.length > 0 &&
                    props.wrapper.program.field_services_disp) ||
                  (props.wrapper.program.field_dwelling_type &&
                    !props.wrapper.program.field_dwelling_type![0] &&
                    props.wrapper.program.field_disp_grid_bare_ownership) ||
                  (props.wrapper.program.field_dwelling_type &&
                    !props.wrapper.program.field_dwelling_type![0] &&
                    helper.isPreview(props.wrapper) &&
                    hasBareOwnershipSlice(props.wrapper))
                }
              >
                <Show
                  fallback={
                    <span
                      innerHTML={`, actabilité  ${closingDelivery(props.wrapper)}`}
                    />
                  }
                  when={closingDeliveryInThePast(props.wrapper)}
                >
                  <span>, actabilité immédiate</span>
                </Show>
              </Show>
            </Show>
          </div>
        </div>

        {/* SECOND COL */}
        <Switch>
          <Match when={!props.wrapper.program.field_address_hide}>
            <div
              class="item pointer"
              data-test="address"
              onClick={() => {
                if (settingsContext.cityscan_is_enabled) {
                  sendClick("btn-map", "introduction");
                  setMediaOverlay(
                    "embed",
                    `<iframe height="100%" width="100%" src="https://www.cityscan.fr/widget?clientKey=${settingsContext.cityscan_client_key}&extAddressId=${props.wrapper.program.field_program_id}&minZoom=11&landingZoom=${props.wrapper.program.field_scale}&maxZoom=18" frameborder="0" allowfullscreen></iframe>`,
                  );
                }
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconLocationOn48pOutline />
              </i>
              <div class="item-content">
                <strong>Adresse</strong>
                <address itemscope itemtype="https://schema.org/PostalAddress">
                  <span itemProp="streetAddress">
                    {props.wrapper.program.field_street}
                    <Show when={props.wrapper.program.field_additional}>
                      {" "}
                      - {props.wrapper.program.field_additional}
                    </Show>
                  </span>
                  <br />
                  <span itemprop="postalCode">
                    {props.wrapper.program.field_postal_code}
                  </span>{" "}
                  <span itemprop="addressLocality">
                    {props.wrapper.program.field_city}
                  </span>
                </address>
              </div>
            </div>
          </Match>
          <Match
            when={
              props.wrapper.program.field_address_hide &&
              props.wrapper.program.field_address_override
            }
          >
            <div
              class="item pointer"
              data-test="address"
              onClick={() => {
                if (settingsContext.cityscan_is_enabled) {
                  sendClick("btn-map", "introduction");
                  setMediaOverlay(
                    "embed",
                    `<iframe height="100%" width="100%" src="https://www.cityscan.fr/widget?clientKey=${settingsContext.cityscan_client_key}&extAddressId=${props.wrapper.program.field_program_id}&minZoom=11&landingZoom=${props.wrapper.program.field_scale}&maxZoom=18" frameborder="0" allowfullscreen></iframe>`,
                  );
                }
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconLocationOn48pOutline />
              </i>
              <div class="item-content">
                <strong>Adresse</strong>
                <address itemscope itemtype="https://schema.org/PostalAddress">
                  <span itemprop="addressLocality">
                    {props.wrapper.program.field_address_override}
                  </span>
                </address>
              </div>
            </div>
          </Match>
        </Switch>

        {/* THIRD COL */}
        <div class="item" data-test="regulations">
          <i aria-hidden="true" class="cog-icon">
            <IconPercent48pOutline />
          </i>
          <div class="item-content">
            <strong>Fiscalité</strong>
            <ul class="regulations">
              <Show when={props.wrapper.program.field_disp_grid_vat_inc}>
                <li>TVA normale</li>
              </Show>
              <Show
                when={
                  props.wrapper.program.field_disp_grid_vat_inc_reduced &&
                  !helper.isPreview(props.wrapper)
                }
              >
                <li>TVA réduite à {getSmallestVatRate(props.wrapper)}</li>
              </Show>
              <Show
                when={
                  props.wrapper.program.field_disp_grid_vat_inc_reduced &&
                  helper.isPreview(props.wrapper)
                }
              >
                <li>TVA réduite</li>
              </Show>
              <Show
                when={props.wrapper.program.field_disp_grid_vat_inc_mastered}
              >
                <Show
                  fallback={<li>Prix maîtrisés</li>}
                  when={isPrixEncadresOverride(props.wrapper)}
                >
                  <li>Prix encadrés</li>
                </Show>
              </Show>
              <Show when={props.wrapper.program.field_disp_grid_vat_inc_brs}>
                <li>Prix BRS</li>
              </Show>
              <Show when={props.wrapper.program.field_disp_grid_bare_ownership}>
                <li>Nue-propriété</li>
              </Show>
              <Show when={props.wrapper.program.field_disp_grid_lmnp_vat_ex}>
                <li>LMNP géré</li>
              </Show>
              <Show when={props.wrapper.program.field_disp_grid_lmnp_vat_inc}>
                <li>LMNP</li>
              </Show>
              <Switch>
                <Match
                  when={
                    props.wrapper.program.field_disp_grid_pinel &&
                    props.wrapper.program.field_disp_grid_pinel_plus &&
                    getPinelAndPinelPlusZones(props.wrapper)
                  }
                >
                  <li>
                    Pinel / Pinel +, zone{" "}
                    {getPinelAndPinelPlusZones(props.wrapper)}
                  </li>
                </Match>
                <Match
                  when={
                    props.wrapper.program.field_disp_grid_pinel &&
                    props.wrapper.program.field_disp_grid_pinel_plus
                  }
                >
                  <li>Pinel / Pinel +</li>
                </Match>
                <Match
                  when={
                    props.wrapper.program.field_disp_grid_pinel &&
                    getPinelZones(props.wrapper)
                  }
                >
                  <li>Pinel, zone {getPinelZones(props.wrapper)}</li>
                </Match>
                <Match when={props.wrapper.program.field_disp_grid_pinel}>
                  <li>Pinel</li>
                </Match>
                <Match
                  when={
                    props.wrapper.program.field_disp_grid_pinel_plus &&
                    getPinelPlusZones(props.wrapper)
                  }
                >
                  <li>Pinel +, zone {getPinelPlusZones(props.wrapper)}</li>
                </Match>
                <Match when={props.wrapper.program.field_disp_grid_pinel_plus}>
                  <li>Pinel +</li>
                </Match>
              </Switch>
              <Show when={props.wrapper.program.field_disp_grid_patrimonial}>
                <li>Patrimonial</li>
              </Show>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

function Introduction(props: PresentationProps) {
  const settingsContext = useDrupalSettingsContext();

  const regulationTids = () => [settingsContext.regulations_tids.lmnp_vat_ex];

  return (
    <>
      <div class="main-text" data-test="body">
        <Show when={props.wrapper.program.field_main_text}>
          <div innerHTML={props.wrapper.program.field_main_text.value} />
        </Show>
        <Show when={props.wrapper.program.field_managers}>
          <p>En co-promotion avec {props.wrapper.program.field_managers}.</p>
        </Show>
        <Show
          when={
            props.wrapper.program.field_services_disp &&
            props.wrapper.program.field_disp_return_rate &&
            getBestReturnRate(props.wrapper, regulationTids())
          }
        >
          <p class="profitability">
            <i aria-hidden="true" class="cog-icon">
              <IconTrendingUp />
            </i>
            Rentabilité jusqu'à :{" "}
            {getBestReturnRate(props.wrapper, regulationTids())}
          </p>
        </Show>
        <Show
          when={
            props.wrapper.program.field_disp_grid_bare_ownership &&
            props.wrapper.program.field_lease_term
          }
        >
          <p class="lease-term">
            <i aria-hidden="true" class="cog-icon">
              <IconTimer />
            </i>
            Durée de l’usufruit temporaire&nbsp;:{" "}
            {props.wrapper.program.field_lease_term}*
          </p>
        </Show>
      </div>
      <p class="georisques">
        Les informations sur les risques auxquels ce bien est exposé sont
        disponibles sur le site Géorisques&nbsp;:{" "}
        <a href="https://www.georisques.gouv.fr" target="_blank">
          www.georisques.gouv.fr
        </a>
      </p>
    </>
  );
}

function Strengths(props: PresentationProps) {
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();

  const strengths = createMemo(() => {
    if (props.wrapper.program.field_strenghts) {
      return props.wrapper.program.field_strenghts!.split("\r\n");
    }
    return null;
  });

  return (
    <>
      <Switch>
        <Match
          when={
            props.wrapper.program.field_strengths_disp?.name ===
            "Afficher points forts standards"
          }
        >
          <ul class="strengths" data-test="strengths-std">
            <For each={strengths()}>{(strength) => <li>{strength}</li>}</For>
          </ul>
        </Match>
        <Match
          when={
            props.wrapper.program.field_strengths_disp?.name ===
            "Afficher points forts LMNP géré (Résidence services)"
          }
        >
          <div class="services-block" data-test="strengths-lmnp-vat-ex">
            <h3>
              Pourquoi investir en LMNP dans une résidence
              <For each={props.wrapper.program.field_dwelling_type}>
                {(dwelling_type: TermEntityReferenceField) => (
                  <span> {dwelling_type.name}</span>
                )}
              </For>
              &nbsp;?
            </h3>
            <ul>
              <li>
                <i aria-hidden="true" class="cog-icon">
                  <IconSecurity />
                </i>
                Loyers garantis
              </li>
              <li>
                <i aria-hidden="true" class="cog-icon">
                  <IconEuro />
                </i>
                Revenus défiscalisés sur le long terme
              </li>
              <li>
                <i aria-hidden="true" class="cog-icon">
                  <IconSell />
                </i>
                Achat hors taxe
              </li>
            </ul>
            <Show when={!hasNoEscape()}>
              <button
                type="button"
                class="btn study"
                data-test="cta-financial-study"
                onClick={(e) => {
                  sendShowEvent(`financial-study`, e, {
                    nid: props.wrapper.program.drupal_internal__nid,
                  });
                  openModalForm!("financial-study", {
                    wrapper: props.wrapper,
                  });
                  setModalFormHeadline!("Étude personnalisée");
                }}
              >
                &Eacute;tude personnalisée
              </button>
            </Show>
          </div>
        </Match>
        <Match
          when={
            props.wrapper.program.field_strengths_disp?.name ===
            "Afficher points forts Nue-propriété"
          }
        >
          <div class="services-block" data-test="strengths-bare-ownership">
            <h3>Pourquoi investir en Nue-propriété&nbsp;?</h3>
            <ul>
              <Show when={props.wrapper.program.field_freehold_percentage}>
                <li>
                  <i aria-hidden="true" class="cog-icon">
                    <IconPercent />
                  </i>
                  <span>
                    Une{" "}
                    <strong>
                      décote de{" "}
                      {props.wrapper.program.field_freehold_percentage}
                      &nbsp;%*
                    </strong>{" "}
                    sur le prix d’achat de votre bien
                  </span>
                </li>
              </Show>
              <Show when={props.wrapper.program.field_lease_term}>
                <li>
                  <i aria-hidden="true" class="cog-icon">
                    <IconAccountBalanceWallet />
                  </i>
                  <span>
                    Aucun frais de gestion, ni de taxe foncière, ni de charges
                    de copropriété courantes{" "}
                    <strong>
                      pendant {props.wrapper.program.field_lease_term}*
                    </strong>
                  </span>
                </li>
              </Show>
              <li>
                <i aria-hidden="true" class="cog-icon">
                  <IconEuro />
                </i>
                Exclusion de l’assiette IFI
              </li>
            </ul>
            <Show when={!hasNoEscape()}>
              <button
                type="button"
                class="btn study"
                data-test="cta-financial-study"
                onClick={(e) => {
                  sendShowEvent(`financial-study`, e, {
                    nid: props.wrapper.program.drupal_internal__nid,
                  });
                  openModalForm!("financial-study", {
                    wrapper: props.wrapper,
                  });
                  setModalFormHeadline!("Étude personnalisée");
                }}
              >
                &Eacute;tude personnalisée
              </button>
            </Show>
          </div>
        </Match>
      </Switch>
    </>
  );
}

{
  /*
function MonthlyPayments(props: PresentationProps) {
  const settingsContext = useDrupalSettingsContext();
  const [isActive, setIsActive] = createSignal(false);
  const [isActiveClick, setIsActiveClick] = createSignal(false);

  return (
    <>
      <Show
        when={
          props.wrapper.program.field_monthly_payments_title &&
          props.wrapper.program.field_program_type?.drupal_internal__tid !==
            settingsContext.program_types.terrains
        }
      >
        <div
          class="monthly-payments"
          classList={{ active: isActive(), "active-click": isActiveClick() }}
          data-test="monthly-payments"
        >
          <div class="monthly-payments-title">
            <div>
              {props.wrapper.program.field_monthly_payments_title}(
              <span
                onClick={() => setIsActiveClick(true)}
                onMouseEnter={() => setIsActive(true)}
                onMouseLeave={() => setIsActive(false)}
              >
                En savoir plus
              </span>
              )
            </div>
          </div>
          <div class="monthly-payments-amount">
            <span class="monthly">Mensualité</span>
            <strong>
              {props.wrapper.program.field_monthly_payment_amount} €
            </strong>
            <span class="legend">/ mois</span>
          </div>
          <Show when={props.wrapper.program.field_monthly_payments_text}>
            <span class="monthly-payments-text">
              <span
                innerHTML={
                  props.wrapper.program.field_monthly_payments_text.value
                }
              />
              <i
                aria-label="Fermer"
                class="cog-icon"
                onClick={() => setIsActiveClick(false)}
              >
                <IconClose />
              </i>
            </span>
          </Show>
        </div>
      </Show>
    </>
  );
}
*/
}

function ProgramDetails(props: PresentationProps) {
  return (
    <>
      {/* details */}
      <section
        class="program-details"
        classList={{ active: detailIsExpanded() }}
        id="details"
        data-ga-zone="program-details"
        data-test="program-details"
      >
        <h2>
          <strong>Le programme</strong> en détails
        </h2>
        {/* district */}
        <Show when={props.wrapper.program.field_district_disp}>
          <DistrictParagraph wrapper={props.wrapper} />
        </Show>
        {/* building */}
        <Show when={props.wrapper.program.field_building_disp}>
          <BuildingParagraph wrapper={props.wrapper} />
        </Show>
        {/* manager */}
        <Show when={props.wrapper.program.field_services_disp}>
          <ManagerParagraph wrapper={props.wrapper} />
        </Show>
        {/* lots */}
        <Show when={props.wrapper.program.field_lots_disp}>
          <LotsParagraph wrapper={props.wrapper} />
        </Show>
        {/* features */}
        <Show
          when={
            props.wrapper.program.field_features.length > 0 &&
            !props.wrapper.program.field_services_disp
          }
        >
          <FeaturesParagraph wrapper={props.wrapper} />
        </Show>
        <Show when={props.wrapper.program.field_services_disp}>
          <ServicesParagraph wrapper={props.wrapper} />
        </Show>
        <Show when={props.wrapper.program.field_invest_disp}>
          <InvestParagraph wrapper={props.wrapper} />
        </Show>
      </section>
    </>
  );
}

function DistrictParagraph(props: PresentationProps) {
  const settingsContext = useDrupalSettingsContext();
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <div class="district-part" data-test="district">
        <div class="flex-row">
          <Show when={props.wrapper.program.field_district_img?.uri?.url}>
            <picture>
              <img
                src={imgProxy(
                  props.wrapper.program.field_district_img!.uri.url,
                  `size:${props.wrapper.program.field_district_img!.meta?.width}:${
                    props.wrapper.program.field_district_img!.meta?.height
                  }/resizing_type:fill`,
                )}
                alt={props.wrapper.program.field_district_img!.meta?.alt}
                height={props.wrapper.program.field_district_img!.meta?.height}
                width={props.wrapper.program.field_district_img!.meta?.width}
                loading="lazy"
              />
            </picture>
          </Show>
          <div
            class="text-part"
            classList={{
              "without-visual":
                !props.wrapper.program.field_district_img?.uri?.url,
            }}
          >
            <h3>
              Le quartier
              <Show
                when={
                  !props.wrapper.program.field_address_hide &&
                  settingsContext.cityscan_is_enabled
                }
              >
                <button
                  type="button"
                  class="btn"
                  data-test="btn-map"
                  onClick={() => {
                    setMediaOverlay(
                      "embed",
                      `<iframe height="100%" width="100%" src="https://www.cityscan.fr/widget?clientKey=${settingsContext.cityscan_client_key}&extAddressId=${props.wrapper.program.field_program_id}&minZoom=11&landingZoom=${props.wrapper.program.field_scale}&maxZoom=18" frameborder="0" allowfullscreen></iframe>`,
                    );
                  }}
                >
                  Voir la carte
                </button>
              </Show>
            </h3>
            <Show when={props.wrapper.program.field_district_text?.value}>
              <div
                class="paragraph-part common-text"
                data-test="body"
                innerHTML={props.wrapper.program.field_district_text!.value}
              />
            </Show>
          </div>
        </div>
        {/* strengths */}
        <Show
          when={
            props.wrapper.program.field_strengths &&
            props.wrapper.program.field_strengths!.length > 0
          }
        >
          <div class="strengths-part" data-test="strengths">
            <For each={props.wrapper.program.field_strengths}>
              {(strength: ParagraphStrength) => (
                <div class="strength" data-test="strength">
                  <img
                    src={"/images/pictos-strength/" + strength.field_picto}
                    alt=""
                    loading="lazy"
                    height="48"
                    width="48"
                  />
                  <span innerHTML={strength.field_label} />
                </div>
              )}
            </For>
          </div>
        </Show>
      </div>
    </>
  );
}

function BuildingParagraph(props: PresentationProps) {
  return (
    <>
      <div class="building-part" data-test="building">
        <div class="flex-row">
          <Show when={props.wrapper.program.field_building_img?.uri?.url}>
            <picture>
              <img
                src={imgProxy(
                  props.wrapper.program.field_building_img!.uri.url,
                  `size:${props.wrapper.program.field_building_img!.meta?.width}:${
                    props.wrapper.program.field_building_img!.meta?.height
                  }/resizing_type:fill`,
                )}
                alt={props.wrapper.program.field_building_img!.meta?.alt}
                height={props.wrapper.program.field_building_img!.meta?.height}
                width={props.wrapper.program.field_building_img!.meta?.width}
                loading="lazy"
              />
            </picture>
          </Show>
          <div
            class="text-part"
            classList={{
              "without-visual":
                !props.wrapper.program.field_building_img?.uri?.url,
            }}
          >
            <h3>La résidence</h3>
            <Show when={props.wrapper.program.field_building_text?.value}>
              <div
                class="paragraph-part common-text"
                data-test="body"
                innerHTML={props.wrapper.program.field_building_text!.value}
              />
            </Show>
            <div class="pictos-normes" data-test="standards">
              <Show when={props.wrapper.program.field_bbc}>
                <img
                  src="/images/pictos-normes/picto-bbc.png"
                  alt="BBC"
                  height="50"
                  width="50"
                  loading="lazy"
                  data-test="bbc"
                />
              </Show>
              <Show when={props.wrapper.program.field_hqe}>
                <img
                  src="/images/pictos-normes/picto-nf-hqe.png"
                  alt="NF HQE"
                  height="38"
                  width="94"
                  loading="lazy"
                  data-test="hqe"
                />
              </Show>
              <Show when={props.wrapper.program.field_nf}>
                <img
                  src="/images/pictos-normes/picto-nf.png"
                  alt="NF"
                  height="50"
                  width="50"
                  loading="lazy"
                  data-test="nf"
                />
              </Show>
              <Show when={props.wrapper.program.field_nf_habitat}>
                <img
                  src="/images/pictos-normes/picto-nf-habitat.png"
                  alt="NF Habitat"
                  height="50"
                  width="50"
                  loading="lazy"
                  data-test="nf-habitat"
                />
              </Show>
              <Show when={props.wrapper.program.field_nf_habitat_hqe}>
                <img
                  src="/images/pictos-normes/picto-nf-habitat-hqe.png"
                  alt="NF Habitat HQE"
                  height="46"
                  width="77"
                  loading="lazy"
                  data-test="nf-habitat-hqe"
                />
              </Show>
              <Show when={props.wrapper.program.field_rt_2012}>
                <img
                  src="/images/pictos-normes/picto-rt2012.png"
                  alt="RT 2012"
                  height="50"
                  width="50"
                  loading="lazy"
                  data-test="rt-2012"
                />
              </Show>
              <Show when={props.wrapper.program.field_re_2020}>
                <img
                  src="/images/pictos-normes/picto-re2020.png"
                  alt="RE 2020"
                  height="38"
                  width="100"
                  loading="lazy"
                  data-test="re-2020"
                />
              </Show>
              <Show when={props.wrapper.program.field_re_2020_2025}>
                <img
                  src="/images/pictos-normes/picto-re2020-seuil2025.png"
                  alt="RE 2020 Seuil 2025"
                  height="50"
                  width="100"
                  loading="lazy"
                  data-test="re-2020-2025"
                />
              </Show>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ManagerParagraph(props: PresentationProps) {
  return (
    <>
      <Show
        when={
          props.wrapper.program.field_manager_text?.value ||
          props.wrapper.program.field_manager_img?.uri.url ||
          props.wrapper.program.field_lease_term_lmnp
        }
      >
        <div class="manager-part" data-test="manager">
          <div class="flex-row">
            <Show when={props.wrapper.program.field_manager_img?.uri?.url}>
              <picture>
                <img
                  src={imgProxy(
                    props.wrapper.program.field_manager_img!.uri.url,
                    `size:${props.wrapper.program.field_manager_img!.meta?.width}:${
                      props.wrapper.program.field_manager_img!.meta?.height
                    }/resizing_type:fill`,
                  )}
                  alt={props.wrapper.program.field_manager_img!.meta?.alt}
                  height={props.wrapper.program.field_manager_img!.meta?.height}
                  width={props.wrapper.program.field_manager_img!.meta?.width}
                  loading="lazy"
                />
              </picture>
            </Show>

            <div
              class="text-part"
              classList={{
                "without-visual":
                  !props.wrapper.program.field_manager_img?.uri?.url,
              }}
            >
              <h3>Le gestionnaire</h3>
              <Show when={props.wrapper.program.field_manager_text?.value}>
                <div
                  class="paragraph-part"
                  data-test="body"
                  innerHTML={props.wrapper.program.field_manager_text!.value}
                />
              </Show>
              <Show when={props.wrapper.program.field_lease_term_lmnp}>
                <div class="lease-term-lmnp" data-test="lease-term">
                  <i aria-hidden="true" class="cog-icon">
                    <IconTimer />
                  </i>
                  Durée du bail : {props.wrapper.program.field_lease_term_lmnp}
                </div>
              </Show>
            </div>
          </div>
        </div>
      </Show>
    </>
  );
}

function LotsParagraph(props: PresentationProps) {
  return (
    <>
      <div class="lots-part" data-test="lots">
        <div class="flex-row">
          <Show when={props.wrapper.program.field_lots_img?.uri?.url}>
            <picture>
              <img
                src={imgProxy(
                  props.wrapper.program.field_lots_img!.uri.url,
                  `size:${props.wrapper.program.field_lots_img!.meta?.width}:${
                    props.wrapper.program.field_lots_img!.meta?.height
                  }/resizing_type:fill`,
                )}
                alt={props.wrapper.program.field_lots_img!.meta?.alt}
                height={props.wrapper.program.field_lots_img!.meta?.height}
                width={props.wrapper.program.field_lots_img!.meta?.width}
                loading="lazy"
              />
            </picture>
          </Show>
          <div
            class="text-part"
            classList={{
              "without-visual": !props.wrapper.program.field_lots_img?.uri?.url,
            }}
          >
            <h3>Les logements</h3>
            <Show when={props.wrapper.program.field_lots_txt?.value}>
              <div
                class="paragraph-part common-text"
                data-test="body"
                innerHTML={props.wrapper.program.field_lots_txt!.value}
              />
            </Show>
          </div>
        </div>
      </div>
    </>
  );
}

function FeaturesParagraph(props: PresentationProps) {
  return (
    <>
      <div class="features-part" data-test="features">
        <h3>Les prestations disponibles dans cette résidence</h3>
        <ul class="features">
          <For each={props.wrapper.program.field_features}>
            {(feature: FeatureReferenceField) => (
              <li data-test="feature">
                <img
                  src={feature.field_icon.uri.url}
                  alt=""
                  height="48"
                  width="48"
                  loading="lazy"
                />
                <span>{feature.name}</span>
              </li>
            )}
          </For>
        </ul>
      </div>
    </>
  );
}

function ServicesParagraph(props: PresentationProps) {
  return (
    <>
      <div class="services-part" data-test="services">
        <h3>Les prestations et services disponibles</h3>
        <Show when={props.wrapper.program.field_services.length > 0}>
          <ul class="services">
            <For each={props.wrapper.program.field_services}>
              {(service: FeatureReferenceField) => (
                <li data-test="service">
                  <img
                    src={service.field_icon.uri.url}
                    alt=""
                    height="48"
                    width="48"
                    loading="lazy"
                  />
                  <span>{service.name}</span>
                </li>
              )}
            </For>
          </ul>
        </Show>
        <Show when={props.wrapper.program.field_services_txt?.value}>
          <div
            class="paragraph-part common-text"
            data-test="body"
            innerHTML={props.wrapper.program.field_services_txt!.value}
          />
        </Show>
      </div>
    </>
  );
}

function InvestParagraph(props: PresentationProps) {
  return (
    <>
      <section
        class="invest-part"
        data-ga-zone="bloc-invest"
        data-test="invest"
      >
        <div class="invest-text">
          <Show when={props.wrapper.program.field_invest_title}>
            <h3 class="invest-title">
              {props.wrapper.program.field_invest_title}
            </h3>
          </Show>
          <Show when={props.wrapper.program.field_invest_text?.value}>
            <div
              data-test="body"
              innerHTML={props.wrapper.program.field_invest_text!.value}
            />
          </Show>
        </div>
      </section>
    </>
  );
}
